

interface TipoBusca {
  cnpj: string,
  nomeFantasia: string,
  grupo: string,
  tipoBusca: string,
  totalConsultas: number
}

interface Consulta {
  anoVeiculo: string,
  cnpj: string,
  crated_at: string,
  grupo: string,
  id: number,
  marcaVeiculo: string,
  modeloVeiculo: string,
  nomeFantasia: string,
}

import { defineComponent, onMounted, Ref, ref, watch } from "vue"
import Title from "@/components/Title.vue";
import Button from "@/components/Button.vue";
import Api from "@/services/Api";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { computed } from 'vue';
import { number } from "yup";
import moment from 'moment';
import ptBr from 'element-plus/lib/locale/lang/pt-br';


export default defineComponent({
  components: { Title, Button },
  name: "RelatorioLoja",
  setup() {

    const router = useRouter();
    const route = useRoute();
    const idLoja: Ref<any> = ref(null);
    const paginasNumeradas: Ref<any> = ref([]);
    const inputFind: Ref<string> = ref('');
    const produto: Ref<any> = ref({});
    const openFind: Ref<boolean> = ref(false);
    const findActive: Ref<boolean> = ref(false);
    const totalBuscas: Ref<number> = ref(0);
    const retornoBusca: Ref<any> = ref(null);
    const dataInput: Ref<any> = ref(route.params.dataInput);
    const isLoadingConsultasLoja: Ref<boolean> = ref(false);
    const isLoadingMarcas: Ref<boolean> = ref(false);

    const totalConsultasLoja: Ref<number | null> = ref(null);
    const storeGroups: Ref<any[]> = ref([]);
    const dataTable: Ref<any[]> = ref([]);
    const nomeLoja: Ref<string> = ref('');

    
   
    
    const seriesMarcas: Ref<any> = ref([]);
    const chartOptionsMarcas = ref({
      chart: { 
        type: "pie" ,
      },
      labels: [],
      legend: { 
        show: true,
        formatter: function(seriesName) {
          const maxLabelLength = 16;
          if (seriesName.length > maxLabelLength) {
            return seriesName.substring(0, maxLabelLength) + '...';
          }
          return seriesName;
        }
      },
      stroke: {
        width: 0,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });

    const seriesLojaConsultas: Ref<any> = ref([]);
    const chartOptionsLojaConsultas = ref({
      chart: { 
        type: "donut",
      },
      labels: [],
      legend: { 
        show: true,
        formatter: function(seriesName) {
          const maxLabelLength = 16;
          if (seriesName.length > maxLabelLength) {
            return seriesName.substring(0, maxLabelLength) + '...';
          }
          return seriesName;
        } 
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      stroke: {
        width: 0,
      },
    });

    
    async function downloadProducts() {
      try {
        const cnpj = route.params.cnpj;
        const codGrupo = route.params.codGrupo;
        const month = new Date(dataInput.value).getMonth() + 1;
        const year = new Date(dataInput.value).getFullYear();
        const res = await Api.get('relatorio/exportRelatorioConsultaLoja', { month, year, codGrupo, cnpj},{ responseType: 'blob' })
        baixaArquivo(res, 'minhas_consultas_indicar.xlsx')
      } catch (err) {
        console.log(err);
      }
    }

    function baixaArquivo(blobParam:any, name:string) {
      const url = URL.createObjectURL(new Blob([blobParam]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(url);
    }


    async function getLoja() {
      var cnpj = route.params.cnpj;
      const { data, error } = await Api.get("relatorio/getLojaByCnpj", {cnpj});
      if(error){
        console.error("Erro ao buscar dados!");
      }else{
        nomeLoja.value = data.original[0].nomeFantasia;
      }
      return;
    }


    async function getRelatorioConsultas() {
      try {
        const cnpj = route.params.cnpj;
        const codGrupo = route.params.codGrupo;
        const month = new Date(dataInput.value).getMonth() + 1;
        const year = new Date(dataInput.value).getFullYear();
        var totalConsultas = 0;
        isLoadingConsultasLoja.value = true;
        isLoadingMarcas.value = true;

        const { data, error } = await Api.get("relatorio/getRelatorioConsultaLoja", { month, year, codGrupo, cnpj});

        if(error){
          console.error("Erro ao buscar dados!");
          return;
        }
        //SOMA DO TOTAL DE CONSULTAS DE CADA GRUPO PARA PEGAR O TOTAL GERAL DE CONSULTAS
        data.dataForTotals.forEach((tipoBusca: TipoBusca) => {
          totalConsultas += tipoBusca.totalConsultas;
        });

        totalConsultasLoja.value = totalConsultas;

        let series = [] as any;
        let chartlabels = [] as any;
       
        data.dataForTotals.forEach((tipoBusca: TipoBusca) => {
          series.push(tipoBusca.totalConsultas);
          chartlabels.push(tipoBusca.tipoBusca);
        });

        dataTable.value = data.data;

        chartOptionsLojaConsultas.value.labels = chartlabels;
        seriesLojaConsultas.value = series;


        //segundo gráfico por marcas
        let series2 = [] as any;
        let totaisMarcas = [] as any;
        let chartlabelsMarcas = [] as any;
        data.data.forEach((consulta: Consulta) => {
          if(!chartlabelsMarcas.includes(consulta.marcaVeiculo)){
            chartlabelsMarcas.push(consulta.marcaVeiculo);
            totaisMarcas[consulta.marcaVeiculo] = 1;
          }else{
            totaisMarcas[consulta.marcaVeiculo] += 1;
          }
          // series.push(tipoBusca.totalConsultas);
          // chartlabels.push(tipoBusca.tipoBusca);
        });

        chartlabelsMarcas.forEach(element => {
          series2.push(totaisMarcas[element]);
        });
        
        chartOptionsMarcas.value.labels = chartlabelsMarcas;
        seriesMarcas.value = series2;

      }catch (error){
        console.log(error);
      }finally{
        isLoadingConsultasLoja.value = false;
        isLoadingMarcas.value = false;

      }
    }


    watch(() => dataInput.value,
      () => {
        getRelatorioConsultas();
      })

    onMounted(() => {
      getRelatorioConsultas();
      getLoja();
    })

    return {
      router,
      totalConsultasLoja,
      paginasNumeradas,
      inputFind,
      produto,
      getRelatorioConsultas,
      idLoja,
      openFind,
      totalBuscas,
      findActive,
      retornoBusca,
      chartOptionsLojaConsultas,
      seriesLojaConsultas,
      dataInput,
      ptBr,
      isLoadingConsultasLoja,
      isLoadingMarcas,
      seriesMarcas,
      chartOptionsMarcas,
      storeGroups,
      dataTable, 
      moment,
      nomeLoja,
      getLoja,
      downloadProducts,
    };
  },
})
